.Language-switcher > button {
  background: none;
  border: none;
  font-size: 0.9em;
  color: white;
  cursor: pointer;
}

.Language-switcher > button:hover {
  opacity: 0.6;
}

.Language-switcher > span {
  color: white;
}