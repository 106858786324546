.footer {
  background-color: black;
  text-align: center;
  color: lightgray;
}

.footer p {
  margin: 0;
  padding: 10px 0;
}

.footer-image-wrapper {
  height: 400px;
  overflow: hidden;
}

.footer-image-wrapper img {
  object-fit: cover;
  object-position: center;
}

.copyright {
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
}
