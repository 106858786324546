.Media .Media-banner {
  display: block;
  background-color: #2e7840;
}

.Media .Media-banner p {
  margin: 0;
  margin-left: -15px;
  padding: 15px;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  display: inline-block;
}

.Media .Media-info-article {
  font-size: 1.2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 50px;
  font-weight: 700;
  width: 100%;
}

.Media .table {
  font-size: 1rem;
}

.Media .Media-title {
  padding: 15px;
  background-color: black;
  color: white;
  margin-bottom: 0;
  margin-right: -15px;
  margin-left: -15px;
  font-weight: 700;
  display: block;
  font-size: 2rem;
}

.Media .Media-info-title {
  margin-bottom: 40px;
  font-weight: 700;
}

.Media .Media-info-text {
  margin-bottom: 40px;
  font-size: 1rem;
  font-weight: 400;
}

.Media-inscription-button-wrapper {
  display: flex;
  justify-content: flex-end;
}

.Media-inscription-button {
  padding: 10px;
  color: white;
  position: relative;
  z-index: 1;
  display: inline-block;
  min-width: 160px;
  padding: 10px 20px;
  margin-left: 5px;
  margin-right: 5px;
  border: 1px solid transparent;
  background: 0 0;
  background-image: none;
  background-image: none;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  line-height: 20px;
  font-weight: 700;
  white-space: nowrap;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 11px;
  letter-spacing: 0.1em;
}

.Media-inscription-button::before {
  content: "";
  transform: skewX(-14deg);
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #2e7840;
}

.Media-inscription-button:hover {
  color: white;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .Media .Media-info-article {
    padding: 20px;
  }

  .Media-button-wrapper {
    justify-content: center;
  }
}

.categorias-title {
  font-family: "Raleway", sans-serif;
  font-weight: 700;

  margin-bottom: 30px;
  color: #348a49;
  text-align: center;
  font-size: 2rem;
  margin-top: 2rem;
}

* {
  font-family: "Montserrat", sans-serif;
}

li {
  margin-bottom: 5px;
}

.categorias-title {
  font-family: "Raleway", sans-serif;
  font-weight: 700;

  margin-bottom: 30px;
  color: #348a49;
  text-align: center;
  font-size: 2rem;
}

.categorias-subtitle {
  margin-bottom: 40px;
}

* {
  font-family: "Montserrat", sans-serif;
}

.categoria-type h5 {
  background-color: #3283ad;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1.5rem;
  padding: 1rem;
  border-radius: 5px;
  color: white;
}

.categoria-type h6 {
  font-size: 40px;
  font-weight: 700;
  margin-top: 40px;
  text-align: right;
}

.categoria-description {
  text-align: justify;
  display: flex;
  justify-content: center;
}

.categoria-description p {
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
}
