.Home-pager {
  background-color: black;
  color: white;
  padding: 10px;
}

.slider-caption2 {
  color: #2e7840;
  font-weight: 700;
  font-size: 40px;
  margin: 0 auto;
  z-index: 4;
  max-width: 80%;
  text-align: center;
  padding: 20px 0;
}

.Home-pager .Home-pager-link {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  height: 100%;
  color: white;
  padding: 50px;
}

.Home-pager .Home-pager-link:hover {
  text-decoration: none;
  opacity: 0.5;
}

.Home-pager .Hover-pager-link:hover .Home-pager-arrow {
  transition: transform ease-in 0.1s;
  transform: scale(1.1);
}

.Home-pager .Home-pager-title {
  font-size: 1.4rem;
  font-weight: 400;
  width: 80%;
  text-transform: uppercase;
}

.Home-pager .Home-pager-subtitle {
  color: #2e7840;
  width: 80%;
}

.Home-pager .Home-pager-arrow-wrapper {
  position: absolute;
  right: 50px;
  top: 50px;
}

.Home-pager .Home-pager-arrow {
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2e7840;
  border-radius: 50%;
  cursor: pointer;
  transition: transform ease-in 0.1s;
}

.Home-info .Home-info-text,
.Home-banner .Home-banner-text {
  font-size: 1.2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 50px;
  font-weight: 700;
  width: 100%;
}

.Home-info .Home-info-title,
.Home-banner .Home-banner-title {
  font-size: 60px;
  color: #2e7840;
  font-weight: 900;
  margin-bottom: 80px;
  width: 80%;
}

.Home-info .Home-info-subtitle,
.Home-banner .Home-banner-subtitle {
  text-align: justify;
}

.Home-banner .Home-banner-img,
.Home-info .Home-info-img {
  margin-right: -15px;
  margin-left: -15px;
  height: 800px;
}

.Home-banner .Home-banner-img img,
.Home-info .Home-info-img img {
  height: auto;
  max-width: 100%;
}

.Home-banner-button-wrapper {
  display: flex;
  justify-content: flex-end;
}

.Home-banner-button {
  padding: 10px;
  color: white;
  position: relative;
  z-index: 1;
  display: inline-block;
  min-width: 160px;
  padding: 10px 20px;
  margin-left: 5px;
  margin-right: 5px;
  border: 1px solid transparent;
  background: 0 0;
  background-image: none;
  background-image: none;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  line-height: 20px;
  font-weight: 700;
  white-space: nowrap;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 11px;
  letter-spacing: .1em;
}

.Home-banner-button::before {
  content: "";
  transform: skewX(-14deg);
  transition: background-color .2s ease-in-out;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #2e7840;
}

.Home-banner-button:hover {
  color: white;
  text-decoration: none;
}

.Home-sponsors {
  padding: 10px;
  min-height: 200px;
  background-color: white;
  border-bottom: 15px solid #2e7840;
  border-top: 15px solid #2e7840;
  padding: 6rem 3rem;
  display: flex;
  justify-content: space-between;
}

.Home-sponsors>* {
  height: auto;
  width: 150px;
  max-height: 100px;
}

.Home-countdown {
  display: none;
}

.Home-countdown .Countdown {
  position: relative;
  left: unset;
  transform: unset;
  display: flex;
  flex-shrink: 1;
  flex-flow: row;
  justify-content: space-between;
  flex-basis: 100%;
}

.Home-countdown .Countdown .Countdown-col {
  width: 22%;
}

.Home-countdown .Countdown .Countdown-col-element {
  width: 80px;
}

.Home-countdown .Countdown-col-element span {
  font-size: 3vmin;
}

.Home .alert {
  position: absolute;
  z-index: 99;
  left: 50%;
  top: 30%;
  transform: translateX(-50%);
}

@media screen and (max-width: 768px) {
  .Home-pager .Home-pager-link {
    padding: 30px;
  }
  .Home-pager .Home-pager-title {
    font-size: 1.2rem;
  }
  .Home-pager .Home-pager-arrow-wrapper {
    right: 15px;
    top: 25px;
  }
  .Home-banner .Home-banner-img,
  .Home-info .Home-info-img {
    height: 400px;
  }
  .Home-info .Home-info-text,
  .Home-banner .Home-banner-text {
    font-size: 1.2rem;
    padding: 20px 20px;
  }
  .Home-info .Home-info-title,
  .Home-banner .Home-banner-title {
    font-size: 40px;
    width: 100%;
  }
  .Home-info .Home-info-subtitle,
  .Home-banner .Home-banner-subtitle {
    text-align: justify;
  }
  .Home-banner-button-wrapper {
    justify-content: center;
  }
  .inscription-fixed-bar {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: black;
    display: flex;
    justify-content: center;
    border-top: 2px solid #2e7840;
    z-index: 999;
  }

  .inscription-fixed-bar a {
    color: white;
  }

  .Home .alert {
    position: absolute;
    z-index: 99;
    left: 50%;
    top: 30%;
    transform: translateX(-50%);
    width: 80%;
  }

  .Home-countdown {
    height: 130px;
    display: flex;
    align-items: center;
  }

  .slider-caption2 {
    font-size: 30px;
  }
}

.plazas-agotadas-banner {
  background-color: #2e7840;

}

.plazas-agotadas-banner h1 {
  color: white;
  font-weight: 800;
}

.hero img {
  margin-left: -15px;
}

.hero-content {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  min-height: 480px;
  height: 100%;
  padding: 20px;
  color: white;
}

.hero-content-title {
  font-family: "Raleway", sans-serif;
  font-weight: 700;
}

.hero-content-subtitle {
  margin-bottom: 20px;
  font-family: "Montserrat", sans-serif;
}

.btn-inscription {
  background-color: #444;
  color: white;
  transition: 0.5s all ease;
  border: none;
}

.btn-inscription:hover {
  background-color: #333;
  border: none;
}

.Home-countdown .Countdown {
  position: relative;
  left: unset;
  transform: unset;
  display: flex;
  flex-shrink: 1;
  flex-flow: row;
  justify-content: space-between;
  flex-basis: 100%;
}

.Home-countdown .Countdown .Countdown-col {
  width: 22%;
}

.Home-countdown .Countdown .Countdown-col-element {
  width: 80px;
}

.Home-countdown .Countdown-col-element span {
  font-size: 3vmin;
}

.Home-pager {
  background-color: black;
  color: white;
  padding: 10px;
  width: 100%;
  transform: translateX(0px);
}

.Home-pager .Home-pager-link {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  height: 100%;
  color: white;
  padding: 50px;
  font-family: "Montserrat", sans-serif;
}

.Home-pager .Home-pager-link:hover {
  text-decoration: none;
  opacity: 0.5;
}

.Home-pager .Hover-pager-link:hover .Home-pager-arrow {
  transition: transform ease-in 0.1s;
  transform: scale(1.1);
}

.Home-pager .Home-pager-title {
  font-size: 18px;
  font-weight: 400;
  width: 80%;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
}

.Home-pager .Home-pager-subtitle {
  color: #2e7840;
  width: 80%;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
}

.Home-pager .Home-pager-arrow-wrapper {
  position: absolute;
  right: 30px;
  top: 45px;
}

.Home-pager .Home-pager-arrow {
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2e7840;
  border-radius: 50%;
  cursor: pointer;
  transition: transform ease-in 0.1s;
}

.description {
  font-family: "Montserrat", sans-serif;
  padding: 100px 40px;
  text-align: center;
}

.description-title {
  font-family: "Raleway", sans-serif;
  font-weight: 700;

  margin-bottom: 30px;
  color: #348a49;
}

.image-grid .img-wrapper {
  height: 300px;
  overflow: hidden;
}

.img-wrapper img {
  object-fit: cover;
  object-position: center;
}

.video {
  margin-bottom: 35px;
}

.gps-guided {
  padding: 1rem;
  margin-top: 6rem;
  font-family: "Sedgwick Ave", cursive;
  font-size: 5rem;
  font-weight: bold;
  color: black;
  line-height: 1;
}
