body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Montserrat", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.leaflet-container {
  height: 800px;
  width: 100%;
}
.Home-pager {
  background-color: black;
  color: white;
  padding: 10px;
}

.slider-caption2 {
  color: #2e7840;
  font-weight: 700;
  font-size: 40px;
  margin: 0 auto;
  z-index: 4;
  max-width: 80%;
  text-align: center;
  padding: 20px 0;
}

.Home-pager .Home-pager-link {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  height: 100%;
  color: white;
  padding: 50px;
}

.Home-pager .Home-pager-link:hover {
  text-decoration: none;
  opacity: 0.5;
}

.Home-pager .Hover-pager-link:hover .Home-pager-arrow {
  transition: -webkit-transform ease-in 0.1s;
  transition: transform ease-in 0.1s;
  transition: transform ease-in 0.1s, -webkit-transform ease-in 0.1s;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.Home-pager .Home-pager-title {
  font-size: 1.4rem;
  font-weight: 400;
  width: 80%;
  text-transform: uppercase;
}

.Home-pager .Home-pager-subtitle {
  color: #2e7840;
  width: 80%;
}

.Home-pager .Home-pager-arrow-wrapper {
  position: absolute;
  right: 50px;
  top: 50px;
}

.Home-pager .Home-pager-arrow {
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2e7840;
  border-radius: 50%;
  cursor: pointer;
  transition: -webkit-transform ease-in 0.1s;
  transition: transform ease-in 0.1s;
  transition: transform ease-in 0.1s, -webkit-transform ease-in 0.1s;
}

.Home-info .Home-info-text,
.Home-banner .Home-banner-text {
  font-size: 1.2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 50px;
  font-weight: 700;
  width: 100%;
}

.Home-info .Home-info-title,
.Home-banner .Home-banner-title {
  font-size: 60px;
  color: #2e7840;
  font-weight: 900;
  margin-bottom: 80px;
  width: 80%;
}

.Home-info .Home-info-subtitle,
.Home-banner .Home-banner-subtitle {
  text-align: justify;
}

.Home-banner .Home-banner-img,
.Home-info .Home-info-img {
  margin-right: -15px;
  margin-left: -15px;
  height: 800px;
}

.Home-banner .Home-banner-img img,
.Home-info .Home-info-img img {
  height: auto;
  max-width: 100%;
}

.Home-banner-button-wrapper {
  display: flex;
  justify-content: flex-end;
}

.Home-banner-button {
  padding: 10px;
  color: white;
  position: relative;
  z-index: 1;
  display: inline-block;
  min-width: 160px;
  padding: 10px 20px;
  margin-left: 5px;
  margin-right: 5px;
  border: 1px solid transparent;
  background: 0 0;
  background-image: none;
  background-image: none;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  line-height: 20px;
  font-weight: 700;
  white-space: nowrap;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 11px;
  letter-spacing: .1em;
}

.Home-banner-button::before {
  content: "";
  -webkit-transform: skewX(-14deg);
          transform: skewX(-14deg);
  transition: background-color .2s ease-in-out;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #2e7840;
}

.Home-banner-button:hover {
  color: white;
  text-decoration: none;
}

.Home-sponsors {
  padding: 10px;
  min-height: 200px;
  background-color: white;
  border-bottom: 15px solid #2e7840;
  border-top: 15px solid #2e7840;
  padding: 6rem 3rem;
  display: flex;
  justify-content: space-between;
}

.Home-sponsors>* {
  height: auto;
  width: 150px;
  max-height: 100px;
}

.Home-countdown {
  display: none;
}

.Home-countdown .Countdown {
  position: relative;
  left: unset;
  -webkit-transform: unset;
          transform: unset;
  display: flex;
  flex-shrink: 1;
  flex-flow: row;
  justify-content: space-between;
  flex-basis: 100%;
}

.Home-countdown .Countdown .Countdown-col {
  width: 22%;
}

.Home-countdown .Countdown .Countdown-col-element {
  width: 80px;
}

.Home-countdown .Countdown-col-element span {
  font-size: 3vmin;
}

.Home .alert {
  position: absolute;
  z-index: 99;
  left: 50%;
  top: 30%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

@media screen and (max-width: 768px) {
  .Home-pager .Home-pager-link {
    padding: 30px;
  }
  .Home-pager .Home-pager-title {
    font-size: 1.2rem;
  }
  .Home-pager .Home-pager-arrow-wrapper {
    right: 15px;
    top: 25px;
  }
  .Home-banner .Home-banner-img,
  .Home-info .Home-info-img {
    height: 400px;
  }
  .Home-info .Home-info-text,
  .Home-banner .Home-banner-text {
    font-size: 1.2rem;
    padding: 20px 20px;
  }
  .Home-info .Home-info-title,
  .Home-banner .Home-banner-title {
    font-size: 40px;
    width: 100%;
  }
  .Home-info .Home-info-subtitle,
  .Home-banner .Home-banner-subtitle {
    text-align: justify;
  }
  .Home-banner-button-wrapper {
    justify-content: center;
  }
  .inscription-fixed-bar {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: black;
    display: flex;
    justify-content: center;
    border-top: 2px solid #2e7840;
    z-index: 999;
  }

  .inscription-fixed-bar a {
    color: white;
  }

  .Home .alert {
    position: absolute;
    z-index: 99;
    left: 50%;
    top: 30%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 80%;
  }

  .Home-countdown {
    height: 130px;
    display: flex;
    align-items: center;
  }

  .slider-caption2 {
    font-size: 30px;
  }
}

.plazas-agotadas-banner {
  background-color: #2e7840;

}

.plazas-agotadas-banner h1 {
  color: white;
  font-weight: 800;
}

.hero img {
  margin-left: -15px;
}

.hero-content {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  min-height: 480px;
  height: 100%;
  padding: 20px;
  color: white;
}

.hero-content-title {
  font-family: "Raleway", sans-serif;
  font-weight: 700;
}

.hero-content-subtitle {
  margin-bottom: 20px;
  font-family: "Montserrat", sans-serif;
}

.btn-inscription {
  background-color: #444;
  color: white;
  transition: 0.5s all ease;
  border: none;
}

.btn-inscription:hover {
  background-color: #333;
  border: none;
}

.Home-countdown .Countdown {
  position: relative;
  left: unset;
  -webkit-transform: unset;
          transform: unset;
  display: flex;
  flex-shrink: 1;
  flex-flow: row;
  justify-content: space-between;
  flex-basis: 100%;
}

.Home-countdown .Countdown .Countdown-col {
  width: 22%;
}

.Home-countdown .Countdown .Countdown-col-element {
  width: 80px;
}

.Home-countdown .Countdown-col-element span {
  font-size: 3vmin;
}

.Home-pager {
  background-color: black;
  color: white;
  padding: 10px;
  width: 100%;
  -webkit-transform: translateX(0px);
          transform: translateX(0px);
}

.Home-pager .Home-pager-link {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  height: 100%;
  color: white;
  padding: 50px;
  font-family: "Montserrat", sans-serif;
}

.Home-pager .Home-pager-link:hover {
  text-decoration: none;
  opacity: 0.5;
}

.Home-pager .Hover-pager-link:hover .Home-pager-arrow {
  transition: -webkit-transform ease-in 0.1s;
  transition: transform ease-in 0.1s;
  transition: transform ease-in 0.1s, -webkit-transform ease-in 0.1s;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.Home-pager .Home-pager-title {
  font-size: 18px;
  font-weight: 400;
  width: 80%;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
}

.Home-pager .Home-pager-subtitle {
  color: #2e7840;
  width: 80%;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
}

.Home-pager .Home-pager-arrow-wrapper {
  position: absolute;
  right: 30px;
  top: 45px;
}

.Home-pager .Home-pager-arrow {
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2e7840;
  border-radius: 50%;
  cursor: pointer;
  transition: -webkit-transform ease-in 0.1s;
  transition: transform ease-in 0.1s;
  transition: transform ease-in 0.1s, -webkit-transform ease-in 0.1s;
}

.description {
  font-family: "Montserrat", sans-serif;
  padding: 100px 40px;
  text-align: center;
}

.description-title {
  font-family: "Raleway", sans-serif;
  font-weight: 700;

  margin-bottom: 30px;
  color: #348a49;
}

.image-grid .img-wrapper {
  height: 300px;
  overflow: hidden;
}

.img-wrapper img {
  object-fit: cover;
  object-position: center;
}

.video {
  margin-bottom: 35px;
}

.gps-guided {
  padding: 1rem;
  margin-top: 6rem;
  font-family: "Sedgwick Ave", cursive;
  font-size: 5rem;
  font-weight: bold;
  color: black;
  line-height: 1;
}

.Header {
  position: absolute;
  align-items: center;
  display: flex;
  top: 0;
  bottom: 0;
  height: 150px;
  z-index: 99;
  width: 100%;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 16%);
}

.header-homepage .Header {
  background-color: rgba(0,0,0,0.4);
  box-shadow: none;
}

.Header .Header-top {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #2e7840;
  padding: 0.5rem;
  display: flex;
  justify-content: flex-end;
}

.Header .Header-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  padding: 0 0.5rem;
}

@media screen and (max-width: 768px) {
  .Header .Header-container {
    flex-direction: column;
    justify-content: center;
    padding: 0;
  }
}

.Header .Logo-wrapper {
  width: 130px;
}

.Header .Logo-wrapper .Logo-img {
  width: 100%;
}

.Header .Header-navbar {
  display: flex;
}

.Header .Header-navbar .Header-list {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  /* height: 90px; */
}

@media screen and (max-width: 768px) {
  .Header .Header-navbar .Header-list {
    width: 100%;
    justify-content: space-around;
  }
}

.Header .Header-navbar .Header-list .Header-item {
  display: flex;
  padding: 0 0.7rem;
  align-items: center;
}

.Header .Header-navbar .Header-list .Header-item .dropdown-menu {
  top: -25px !important;
  background-color: #2e7840;
  border-radius: 0;
}

.Header .Header-navbar .Header-list .Header-item .dropdown-menu .dropdown-item-a {
  color: white !important;
  font-weight: 600;
}

.Header .Header-navbar .Header-list .Header-item .dropdown-menu .dropdown-item-a:hover {
  background: none;
  opacity: 0.8;
}

@media screen and (max-width: 768px) {
  .Header .Header-navbar .Header-list .Header-item {
    padding: 0 0.35rem;
  }
}

.Header .Header-navbar .Header-list .Header-item .Header-link {
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 0.85rem;
  font-weight: 800;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  color: #444;
}

.header-homepage .Header .Header-navbar .Header-list .Header-item .Header-link {
  color: white;
}

.Header .Header-navbar .Header-list .Header-item .Header-link.Header-link-inscription {
  color: white;
}

.Header .Header-navbar .Header-list .Header-item .Header-link.Header-link-inscription span {
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 0.85rem;
  font-weight: 800;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
}

.Header-link-dropdown {
  background-color: transparent !important;
  padding: 0 !important;
}

.Header-link-dropdown:focus {
  outline: none !important;
}

@media screen and (max-width: 768px) {
  .Header .Header-navbar .Header-list .Header-item .Header-link {
    font-size: 0.85rem;
  }
}

.Header-item-inscription {
  color: white;
  background-color: #2e7840;
}

.Header-item-check-inscription {
  color: white;
  background-color: black;
}

.Header-item ul {
  background-color: #2e7840;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.Header-item ul > li.dropdown-item:hover {
  background-color: #1d502a;
  color: white;
}

.header-mobile {
  position: absolute;
  top: 0;
  min-height: 70px;
  width: 100%;
  z-index: 22;
}

.header-mobile .header-wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
}

.header-mobile .Logo-wrapper {
  width: 100px;
  height: auto;
}

.header-mobile .Logo-wrapper img {
  max-width: 100%;
}

.header-mobile button {
  background-color: transparent;
}

.header-mobile button img {
  width: 25px;
  height: auto;
}


#flyoutMenu {
  width: 100vw;
  height: 100vh;
  background-color: #2e7840;
  position: fixed;
  top: 0;
  left: 0;
  transition: -webkit-transform .5s cubic-bezier(0, .52, 0, 1);
  transition: transform .5s cubic-bezier(0, .52, 0, 1);
  transition: transform .5s cubic-bezier(0, .52, 0, 1), -webkit-transform .5s cubic-bezier(0, .52, 0, 1);
  overflow: scroll;
  z-index: 1000;
  display: flex;
  justify-content: flex-start;
  padding-top: 60px;
  flex-flow: column nowrap;
}
 
#flyoutMenu.hide {
  -webkit-transform: translate3d(-100vw, 0, 0);
          transform: translate3d(-100vw, 0, 0);
}
 
#flyoutMenu.show {
  -webkit-transform: translate3d(0vw, 0, 0);
          transform: translate3d(0vw, 0, 0);
  overflow: hidden;
}

#flyoutMenu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
 
#flyoutMenu h3 a {
  color: white;
  padding-left: 15px;
  text-decoration: none;
  padding: 0.5rem;
  display: block;
  font-size: 7vmin;
}

#flyoutMenu h3 a span {
  font-size: 7vmin;
  text-decoration: none;
}

#flyoutMenu h3 a:hover {
  background-color: #1d502a;
}

#flyoutMenu button {
  position: absolute;
  right: 0;
  top: 23px;
  background-color: transparent;
}

#flyoutMenu button img {
  width: 25px;
  height: auto;
}

#flyoutMenu .menu-mobile-collapse {
  background-color: #1d502a;
}

#flyoutMenu .menu-mobile-collapse a {
  color: white;
  padding: 0.5rem 1.5rem;
}

#flyoutMenu .menu-mobile-collapse a:hover {
  background-color: #1d502a;
}

#flyoutMenu .Menu-mobile-button-wrapper {
  display: flex;
  justify-content: center;
  width:100%;
}

#flyoutMenu .Menu-mobile-button {
  padding: 10px;
  color: #2e7840;
  position: relative;
  z-index: 1;
  display: inline-block;
  min-width: 160px;
  padding: 10px 20px;
  margin-left: 5px;
  margin-right: 5px;
  border: 1px solid transparent;
  background: 0 0;
  background-image: none;
  background-image: none;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  line-height: 20px;
  font-weight: 700;
  white-space: nowrap;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 11px;
  letter-spacing: .1em;
}

#flyoutMenu .Menu-mobile-button::before {
  content: "";
  -webkit-transform: skewX(-14deg);
          transform: skewX(-14deg);
  transition: background-color .2s ease-in-out;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
  background-color: white;
}

#flyoutMenu .Menu-mobile-button:hover {
  color: #2e7840;
  text-decoration: none;
}

#flyoutMenu .Header-top {
  position: absolute;
  left: 0;
  top: 0;
  background-color: white;
  padding: 0.5rem;
  display: flex;
  justify-content: flex-end;
}

#flyoutMenu .Header-top .Language-switcher > button {
  background: none;
  border: none;
  font-size: 0.9em;
  color: white;
  cursor: pointer;
}

#flyoutMenu .Header-top .Language-switcher > button:hover {
  opacity: 0.6;
}

#flyoutMenu .Header-top .Language-switcher > span {
  color: white;
}

.Language-switcher > button {
  background: none;
  border: none;
  font-size: 0.9em;
  color: white;
  cursor: pointer;
}

.Language-switcher > button:hover {
  opacity: 0.6;
}

.Language-switcher > span {
  color: white;
}
.footer {
  background-color: black;
  text-align: center;
  color: lightgray;
}

.footer p {
  margin: 0;
  padding: 10px 0;
}

.footer-image-wrapper {
  height: 400px;
  overflow: hidden;
}

.footer-image-wrapper img {
  object-fit: cover;
  object-position: center;
}

.copyright {
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
}

.Countdown {
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  margin-left: auto;
  margin-right: auto;
  z-index: 9;
}

.Countdown-col {
  display: inline-block;
}

.Countdown-col-element {
  display: inline-block;
  margin: 0 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #2e7840;
  border-radius: 5px;
  width: 100px;
  padding: 5px;
}

.Countdown-col-element strong {
  font-size: 2em;
  color: white;
}

.Countdown-col-element span {
  font-size: 1.2em;
  color: white;
}
.gps {
  font-family: "Sedgwick Ave Display", cursive;
  font-size: 45px !important;
}

.slider-caption {
  position: absolute;
  bottom: 100px;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: white;
  margin: 0;
  z-index: 4;
  max-width: 80%;
  text-align: center;
}

.slider-caption h1 {
  font-weight: bold;
  font-size: 65px;
}


.slider-caption h3 {
  text-align: center;
}

.slider-caption h5 {
  font-weight: bold;
  font-size: 32px;
}

.slider-caption p {
  font-weight: bold;
  font-size: 25px;
}

.slider {
  position: relative;
  width: 100%;
  margin: 0 auto;
  height: 600px;
  overflow: hidden;
  white-space: nowrap;
}

.slider-wrapper {
  position: relative;
  height: 600px;
  width: 100%;
  transition: -webkit-transform ease-out 0.5s;
  transition: transform ease-out 0.5s;
  transition: transform ease-out 0.5s, -webkit-transform ease-out 0.5s;
}

.slider-black {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  height: 600px;
  width: 100%;
  background-color: black;
  opacity: 0.3;
}

.slider-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  height: 600px;
  width: 100%;
  background-repeat: no-repeat;
}

.slide {
  display: inline-block;
  height: 600px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 60%;
}

.right-arrow,
.left-arrow {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f9f9f9;
  border-radius: 50%;
  cursor: pointer;
  transition: -webkit-transform ease-in 0.1s;
  transition: transform ease-in 0.1s;
  transition: transform ease-in 0.1s, -webkit-transform ease-in 0.1s;
}

.right-arrow:hover,
.left-arrow:hover {
  transition: -webkit-transform ease-in 0.1s;
  transition: transform ease-in 0.1s;
  transition: transform ease-in 0.1s, -webkit-transform ease-in 0.1s;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.cool-buttons {
  background: black;
  border: 2px solid white;
}

.right-arrow {
  position: absolute;
  top: 50%;
  right: 25px;
  z-index: 3;
  color: #fff;
}

.right-arrow img {
  -webkit-transform: translate(2px, 0);
          transform: translate(2px, 0);
}

.right-arrow img:focus {
  outline: 0;
}

.left-arrow {
  position: absolute;
  top: 50%;
  left: 25px;
  z-index: 3;
  color: #fff;
}

.left-arrow img {
  -webkit-transform: translate(-2px, 0);
          transform: translate(-2px, 0);
}

.left-arrow img:focus {
  outline: 0;
}

@media screen and (max-width: 768px) {
  .slider-wrapper,
  .slider-overlay,
  .slider,
  .slide {
    height: 250px !important;
  }

  .slider-caption {
    bottom: 0;
  }

  .slider-caption {
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }

  .slider-caption h1 {
    font-size: 6vmin !important;
  }

  .slider-caption h5 {
    font-size: 4vmin !important;
  }

  .slider-caption p {
    font-size: 3vmin !important;
  }

  .right-arrow {
    top: 76%;
  }

  .left-arrow {
    top: 76%;
  }
}

.Rates .Rates-banner {
  display: block;
  background-color: #2e7840;
}

.Rates .Rates-banner p {
  margin: 0;
  margin-left: -15px;
  padding: 15px;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  display: inline-block;
}

.Rates .Rates-info-article {
  font-size: 1.2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 50px;
  font-weight: 700;
  width: 100%;
}

.Rates .table {
  font-size: 1rem;
}

.Rates .Rates-title {
  padding: 15px;
  background-color: black;
  color: white;
  margin-bottom: 0;
  margin-right: -15px;
  margin-left: -15px;
  font-weight: 700;
  display: block;
  font-size: 2rem;
}

.Rates .Rates-info-title {
  margin-bottom: 40px;
  font-weight: 700;

}

.Rates .Rates-info-text {
  margin-bottom: 40px;
  font-size: 1rem;
  font-weight: 400;
}

.Rates-inscription-button-wrapper {
  display: flex;
  justify-content: flex-end;
}

.Rates-inscription-button {
  padding: 10px;
  color: white;
  position: relative;
  z-index: 1;
  display: inline-block;
  min-width: 160px;
  padding: 10px 20px;
  margin-left: 5px;
  margin-right: 5px;
  border: 1px solid transparent;
  background: 0 0;
  background-image: none;
  background-image: none;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  line-height: 20px;
  font-weight: 700;
  white-space: nowrap;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 11px;
  letter-spacing: .1em;
}

.Rates-inscription-button::before {
  content: "";
  -webkit-transform: skewX(-14deg);
          transform: skewX(-14deg);
  transition: background-color .2s ease-in-out;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #2e7840;
}

.Rates-inscription-button:hover {
  color: white;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .Rates .Rates-info-article {
    padding: 20px;
  }

  .Rates-inscription-button-wrapper {
    justify-content: center;
  }
}

.tarifas-title {
  font-family: "Raleway", sans-serif;
  font-weight: 700;

  margin-bottom: 30px;
  color: #348a49;
  text-align: center;
  font-size: 2rem;
  margin-top: 2rem;
}

.que-incluye {
  background-color: #348a49;
  color: white;
  padding: 90px;
  height: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  text-align: justify;
}

.que-incluye h6 {
  text-transform: uppercase;
  font-size: 1.3rem;
  font-weight: 700;
  font-family: "Raleway", sans-serif;
}

.lista-tarifas {
  padding: 40px 100px;
  height: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
}

@media screen and (max-width: 768px) {
  .lista-tarifas,
  .que-incluye {
    padding: 40px;
  }
}

.paquetes-banner {
  position: relative;
  height: 450px;
  overflow: hidden;
}

.paquetes-banner .overlay {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.6;
}

.paquetes-banner .paquetes-banner-info {
  position: absolute;
  top: 50%;
  left: 65%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
}

.paquetes-banner .paquetes-banner-info h5 {
  font-size: 2rem;
  text-transform: uppercase;
  font-family: "Raleway", sans-serif;
  text-align: center;
}

.paquetes-banner .paquetes-banner-info p,
.paquetes-banner .paquetes-banner-info a {
  text-align: center;
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 15px;
}

span {
  font-size: 17px;
  text-decoration: underline;
}

.periodo-title {
  background-color: #348a49;
  display: inline-block;
  padding: 0.5rem;
  color: white;
  border-radius: 0.5rem;
}

* {
  text-align: justify;
}

.Rules .Rules-banner {
  display: block;
  background-color: #2e7840;
}

.Rules .Rules-banner p {
  margin: 0;
  margin-left: -15px;
  padding: 15px;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  display: inline-block;
}

.Rules .Rules-info-article {
  font-size: 1.2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 50px;
  font-weight: 700;
  width: 100%;
}

.Rules .table {
  font-size: 1rem;
}

.Rules .Rules-title {
  padding: 15px;
  background-color: black;
  color: white;
  margin-bottom: 0;
  margin-right: -15px;
  margin-left: -15px;
  font-weight: 700;
  display: block;
  font-size: 2rem;
}

.Rules .Rules-info-title {
  margin-bottom: 40px;
  font-weight: 700;

}

.Rules .Rules-info-text {
  margin-bottom: 40px;
  font-size: 1rem;
  font-weight: 400;
}

.Rules-pdf-button-wrapper {
  display: flex;
  justify-content: flex-end;
}

.Rules-pdf-button {
  padding: 10px;
  color: white;
  position: relative;
  z-index: 1;
  display: inline-block;
  min-width: 160px;
  padding: 10px 20px;
  margin-left: 5px;
  margin-right: 5px;
  border: 1px solid transparent;
  background: 0 0;
  background-image: none;
  background-image: none;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  line-height: 20px;
  font-weight: 700;
  white-space: nowrap;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 11px;
  letter-spacing: .1em;
}

.Rules-pdf-button::before {
  content: "";
  -webkit-transform: skewX(-14deg);
          transform: skewX(-14deg);
  transition: background-color .2s ease-in-out;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #2e7840;
}

.Rules-pdf-button:hover {
  color: white;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .Rules .Rules-info-article {
    padding: 20px;
  }

  .Rules-button-wrapper {
    display: flex;
    justify-content: center;
  }
}

.reglamento-title {
  font-family: "Raleway", sans-serif;
  font-weight: 700;

  margin-bottom: 30px;
  color: #348a49;
  text-align: center;
  font-size: 2rem;
  margin-top: 2rem;
}

p {
  font-family: "Montserrat", sans-serif;
}

.Hosting-web {
  color: #444;
}

.Hosting .Hosting-banner {
  display: block;
  background-color: #2e7840;
}

.Hosting .Hosting-banner p {
  margin: 0;
  margin-left: -15px;
  padding: 15px;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  display: inline-block;
}

.Hosting .Hosting-info-article {
  font-size: 1.2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 50px;
  font-weight: 700;
  width: 100%;
}

.Hosting .table {
  font-size: 1rem;
}

.Hosting .Hosting-title {
  padding: 15px;
  background-color: black;
  color: white;
  margin-bottom: 0;
  margin-right: -15px;
  margin-left: -15px;
  font-weight: 700;
  display: block;
  font-size: 2rem;
}

.Hosting .Hosting-info-title {
  margin-bottom: 40px;
  font-weight: 700;

}

.Hosting .Hosting-info-text {
  margin-bottom: 40px;
  font-size: 1rem;
  font-weight: 400;
}

.Hosting-inscription-button-wrapper {
  display: flex;
  justify-content: flex-end;
}

.Hosting-inscription-button {
  padding: 10px;
  color: white;
  position: relative;
  z-index: 1;
  display: inline-block;
  min-width: 160px;
  padding: 10px 20px;
  margin-left: 5px;
  margin-right: 5px;
  border: 1px solid transparent;
  background: 0 0;
  background-image: none;
  background-image: none;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  line-height: 20px;
  font-weight: 700;
  white-space: nowrap;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 11px;
  letter-spacing: .1em;
}

.Hosting-inscription-button::before {
  content: "";
  -webkit-transform: skewX(-14deg);
          transform: skewX(-14deg);
  transition: background-color .2s ease-in-out;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #2e7840;
}

.Hosting-inscription-button:hover {
  color: white;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .Hosting .Hosting-info-article {
    padding: 20px;
  }

  .Hosting-button-wrapper {
    justify-content: center;
  }
}

.Hosting-info article {
  height: 100%;
}

.paquetes-title {
  font-family: "Raleway", sans-serif;
  font-weight: 700;

  margin-bottom: 30px;
  color: #348a49;
  text-align: center;
  font-size: 2rem;
  margin-top: 2rem;
}

.paquetes-subtitle {
  margin-bottom: 40px;
}

* {
  font-family: "Montserrat", sans-serif;
}

.paquete-type {
  background-color: #348a49;
  color: white;
  min-height: 300px;
}

.paquete-type h5 {
  font-weight: 700;
  text-transform: uppercase;
  font-style: italic;
  font-size: 35px;
}

.paquete-type h6 {
  font-size: 40px;
  font-weight: 700;
  margin-top: 40px;
  text-align: right;
}

.paquete-description {
  background-color: #1c4826;
  min-height: 300px;
  color: white;
  display: flex;
  justify-content: center;
}

.paquete-description p {
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
}

.Sponsors .Sponsors-banner {
  display: block;
  background-color: #2e7840;
}

.Sponsors .Sponsors-banner p {
  margin: 0;
  margin-left: -15px;
  padding: 15px;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  display: inline-block;
}

.Sponsors .Sponsors-info-article {
  font-size: 1.2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 50px;
  font-weight: 700;
  width: 100%;
}

.Sponsors .table {
  font-size: 1rem;
}

.Sponsors .Sponsors-title {
  padding: 15px;
  background-color: black;
  color: white;
  margin-bottom: 0;
  margin-right: -15px;
  margin-left: -15px;
  font-weight: 700;
  display: block;
  font-size: 2rem;
}

.Sponsors .Sponsors-info-title {
  margin-bottom: 40px;
  font-weight: 700;

}

.Sponsors .Sponsors-info-text {
  margin-bottom: 40px;
  font-size: 1rem;
  font-weight: 400;
}

.Sponsors-pdf-button-wrapper {
  display: flex;
  justify-content: flex-end;
}

.Sponsors-pdf-button {
  padding: 10px;
  color: white;
  position: relative;
  z-index: 1;
  display: inline-block;
  min-width: 160px;
  padding: 10px 20px;
  margin-left: 5px;
  margin-right: 5px;
  border: 1px solid transparent;
  background: 0 0;
  background-image: none;
  background-image: none;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  line-height: 20px;
  font-weight: 700;
  white-space: nowrap;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 11px;
  letter-spacing: .1em;
}

.Sponsors-pdf-button::before {
  content: "";
  -webkit-transform: skewX(-14deg);
          transform: skewX(-14deg);
  transition: background-color .2s ease-in-out;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #2e7840;
}

.Sponsors-pdf-button:hover {
  color: white;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .Sponsors .Sponsors-info-article {
    padding: 20px;
  }

  .Sponsors-button-wrapper {
    display: flex;
    justify-content: center;
  }
}

.Sponsors .sponsor-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20rem;
}

.Sponsors .sponsor-wrapper.second-sponsor {
  height: 15rem;
}

.Sponsors .sponsor-wrapper.second-sponsor img {
  max-height: 10rem;
}

.Sponsors .sponsor-wrapper.third-sponsor {
  height: 6rem;
}

.Sponsors .sponsor-wrapper.third-sponsor img {
  max-height: 4rem;
}

.sponsor-second {
  
}
.Seguro .Seguro-banner {
  display: block;
  background-color: #2e7840;
}

.Seguro .Seguro-banner p {
  margin: 0;
  margin-left: -15px;
  padding: 15px;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  display: inline-block;
}

.Seguro .Seguro-info-article {
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 50px;
  font-weight: 700;
  width: 100%;
}

.Seguro .table {
  font-size: 1rem;
}

.Seguro .Seguro-title {
  padding: 15px;
  background-color: black;
  color: white;
  margin-bottom: 0;
  margin-right: -15px;
  margin-left: -15px;
  font-weight: 700;
  display: block;
  font-size: 2rem;
}

.Seguro .Seguro-info-title {
  margin-bottom: 40px;
  font-weight: 700;

}

.Seguro .Seguro-info-text {
  margin-bottom: 40px;
  font-size: 1rem;
  font-weight: 400;
}

.Seguro-pdf-button-wrapper {
  display: flex;
  justify-content: flex-end;
}

.Seguro-pdf-button {
  padding: 10px;
  color: white;
  position: relative;
  z-index: 1;
  display: inline-block;
  min-width: 160px;
  padding: 10px 20px;
  margin-left: 5px;
  margin-right: 5px;
  border: 1px solid transparent;
  background: 0 0;
  background-image: none;
  background-image: none;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  line-height: 20px;
  font-weight: 700;
  white-space: nowrap;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 11px;
  letter-spacing: .1em;
}

.Seguro-pdf-button::before {
  content: "";
  -webkit-transform: skewX(-14deg);
          transform: skewX(-14deg);
  transition: background-color .2s ease-in-out;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #2e7840;
}

.Seguro-pdf-button:hover {
  color: white;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .Seguro .Seguro-info-article {
    padding: 20px;
  }

  .Seguro-button-wrapper {
    display: flex;
    justify-content: center;
  }
}

.tarifas-title {
  font-family: "Raleway", sans-serif;
  font-weight: 700;

  margin-bottom: 30px;
  color: #348a49;
  text-align: center;
  font-size: 2rem;
  margin-top: 2rem;
}
.Embassador .Embassador-banner {
  display: block;
  background-color: #c21718;
}

.Embassador .Embassador-banner p {
  margin: 0;
  margin-left: -15px;
  padding: 15px;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  display: inline-block;
}

.Embassador .Embassador-info-article {
  font-size: 1.2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 50px;
  font-weight: 700;
  width: 100%;
}

.Embassador .table {
  font-size: 1rem;
}

.Embassador .Embassador-title {
  padding: 15px;
  background-color: black;
  color: white;
  margin-bottom: 0;
  margin-right: -15px;
  margin-left: -15px;
  font-weight: 700;
  display: block;
  font-size: 2rem;
}

.Embassador .Embassador-info-title {
  margin-bottom: 40px;
  font-weight: 700;

}

.Embassador .Embassador-info-text {
  margin-bottom: 40px;
  font-size: 1rem;
  font-weight: 400;
}

.Embassador-inscription-button-wrapper {
  display: flex;
  justify-content: flex-end;
}

.Embassador-inscription-button {
  padding: 10px;
  color: white;
  position: relative;
  z-index: 1;
  display: inline-block;
  min-width: 160px;
  padding: 10px 20px;
  margin-left: 5px;
  margin-right: 5px;
  border: 1px solid transparent;
  background: 0 0;
  background-image: none;
  background-image: none;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  line-height: 20px;
  font-weight: 700;
  white-space: nowrap;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 11px;
  letter-spacing: .1em;
}

.Embassador-inscription-button::before {
  content: "";
  -webkit-transform: skewX(-14deg);
          transform: skewX(-14deg);
  transition: background-color .2s ease-in-out;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #c21718;
}

.Embassador-inscription-button:hover {
  color: white;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .Embassador .Embassador-info-article {
    padding: 20px;
  }

  .Embassador-button-wrapper {
    justify-content: center;
  }
}

.etapas-title {
  font-family: "Raleway", sans-serif;
  font-weight: 700;

  margin-bottom: 30px;
  color: #348a49;
  text-align: center;
  font-size: 2rem;
  margin-top: 2rem;
}

.etapa-info {
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
  height: 100%;
  padding: 50px;
}

.etapa-datos {
  display: flex;
  flex-flow: row nowrap;
}

.etapa-datos p {
  text-align: justify;
  font-family: "Montserrat", sans-serif;
  color: #348a49;
  font-size: 13px;
  padding: 0 10px;

}

.etapa-datos p:first-of-type {
  padding: 0;
}

.etapa-info-text {
  text-align: justify;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
}

.etapa-info h5 {
  color: #3283ad;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
}

.img-etapa1 {
  margin-left: -15px;
}

.img-etapa2 {
  margin-right: -15px;
}

.img-etapa3 {
  margin-left: -15px;
}

.Embassador .Embassador-banner {
  display: block;
  background-color: #2e7840;
}

.Embassador .Embassador-banner p {
  margin: 0;
  margin-left: -15px;
  padding: 15px;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  display: inline-block;
}

.Embassador .Embassador-info-article {
  font-size: 1.2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 50px;
  font-weight: 700;
  width: 100%;
}

.Embassador .table {
  font-size: 1rem;
}

.Embassador .Embassador-title {
  padding: 15px;
  background-color: black;
  color: white;
  margin-bottom: 0;
  margin-right: -15px;
  margin-left: -15px;
  font-weight: 700;
  display: block;
  font-size: 2rem;
}

.Embassador .Embassador-info-title {
  margin-bottom: 40px;
  font-weight: 700;

}

.Embassador .Embassador-info-text {
  margin-bottom: 40px;
  font-size: 1rem;
  font-weight: 400;
}

.Embassador-inscription-button-wrapper {
  display: flex;
  justify-content: flex-end;
}

.Embassador-inscription-button {
  padding: 10px;
  color: white;
  position: relative;
  z-index: 1;
  display: inline-block;
  min-width: 160px;
  padding: 10px 20px;
  margin-left: 5px;
  margin-right: 5px;
  border: 1px solid transparent;
  background: 0 0;
  background-image: none;
  background-image: none;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  line-height: 20px;
  font-weight: 700;
  white-space: nowrap;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 11px;
  letter-spacing: .1em;
}

.Embassador-inscription-button::before {
  content: "";
  -webkit-transform: skewX(-14deg);
          transform: skewX(-14deg);
  transition: background-color .2s ease-in-out;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #2e7840;
}

.Embassador-inscription-button:hover {
  color: white;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .Embassador .Embassador-info-article {
    padding: 20px;
  }

  .Embassador-button-wrapper {
    justify-content: center;
  }
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: #2e7840;
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23c21718'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: #2e7840;
  outline: 0;
}

.accordion-header {
  margin-bottom: 0;
  color: #3283ad;
}

.accordion-header  button{
  color: #3283ad;
}

.accordion-item {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.accordion-item:first-of-type {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}


.participantes-title {
  font-family: "Raleway", sans-serif;
  font-weight: 700;

  margin-bottom: 30px;
  color: #348a49;
  text-align: center;
  font-size: 2rem;
  margin-top: 2rem;
}

.card {
  border: none;
}

.card-header {
  background-color: transparent;
  color: #444;
}

.card .btn-link {
  color: #3283ad;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 18px;
}

.card .card-body {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
}

.Media .Media-banner {
  display: block;
  background-color: #2e7840;
}

.Media .Media-banner p {
  margin: 0;
  margin-left: -15px;
  padding: 15px;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  display: inline-block;
}

.Media .Media-info-article {
  font-size: 1.2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 50px;
  font-weight: 700;
  width: 100%;
}

.Media .table {
  font-size: 1rem;
}

.Media .Media-title {
  padding: 15px;
  background-color: black;
  color: white;
  margin-bottom: 0;
  margin-right: -15px;
  margin-left: -15px;
  font-weight: 700;
  display: block;
  font-size: 2rem;
}

.Media .Media-info-title {
  margin-bottom: 40px;
  font-weight: 700;
}

.Media .Media-info-text {
  margin-bottom: 40px;
  font-size: 1rem;
  font-weight: 400;
}

.Media-inscription-button-wrapper {
  display: flex;
  justify-content: flex-end;
}

.Media-inscription-button {
  padding: 10px;
  color: white;
  position: relative;
  z-index: 1;
  display: inline-block;
  min-width: 160px;
  padding: 10px 20px;
  margin-left: 5px;
  margin-right: 5px;
  border: 1px solid transparent;
  background: 0 0;
  background-image: none;
  background-image: none;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  line-height: 20px;
  font-weight: 700;
  white-space: nowrap;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 11px;
  letter-spacing: 0.1em;
}

.Media-inscription-button::before {
  content: "";
  -webkit-transform: skewX(-14deg);
          transform: skewX(-14deg);
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #2e7840;
}

.Media-inscription-button:hover {
  color: white;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .Media .Media-info-article {
    padding: 20px;
  }

  .Media-button-wrapper {
    justify-content: center;
  }
}

.categorias-title {
  font-family: "Raleway", sans-serif;
  font-weight: 700;

  margin-bottom: 30px;
  color: #348a49;
  text-align: center;
  font-size: 2rem;
  margin-top: 2rem;
}

* {
  font-family: "Montserrat", sans-serif;
}

li {
  margin-bottom: 5px;
}

.categorias-title {
  font-family: "Raleway", sans-serif;
  font-weight: 700;

  margin-bottom: 30px;
  color: #348a49;
  text-align: center;
  font-size: 2rem;
}

.categorias-subtitle {
  margin-bottom: 40px;
}

* {
  font-family: "Montserrat", sans-serif;
}

.categoria-type h5 {
  background-color: #3283ad;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1.5rem;
  padding: 1rem;
  border-radius: 5px;
  color: white;
}

.categoria-type h6 {
  font-size: 40px;
  font-weight: 700;
  margin-top: 40px;
  text-align: right;
}

.categoria-description {
  text-align: justify;
  display: flex;
  justify-content: center;
}

.categoria-description p {
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
}

.Media .Media-banner {
  display: block;
  background-color: #2e7840;
}

.Media .Media-banner p {
  margin: 0;
  margin-left: -15px;
  padding: 15px;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  display: inline-block;
}

.Media .Media-info-article {
  font-size: 1.2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 50px;
  font-weight: 700;
  width: 100%;
}

.Media .table {
  font-size: 1rem;
}

.Media .Media-title {
  padding: 15px;
  background-color: black;
  color: white;
  margin-bottom: 0;
  margin-right: -15px;
  margin-left: -15px;
  font-weight: 700;
  display: block;
  font-size: 2rem;
}

.Media .Media-info-title {
  margin-bottom: 40px;
  font-weight: 700;

}

.Media .Media-info-text {
  margin-bottom: 40px;
  font-size: 1rem;
  font-weight: 400;
}

.Media-inscription-button-wrapper {
  display: flex;
  justify-content: flex-end;
}

.Media-inscription-button {
  padding: 10px;
  color: white;
  position: relative;
  z-index: 1;
  display: inline-block;
  min-width: 160px;
  padding: 10px 20px;
  margin-left: 5px;
  margin-right: 5px;
  border: 1px solid transparent;
  background: 0 0;
  background-image: none;
  background-image: none;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  line-height: 20px;
  font-weight: 700;
  white-space: nowrap;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 11px;
  letter-spacing: .1em;
}

.Media-inscription-button::before {
  content: "";
  -webkit-transform: skewX(-14deg);
          transform: skewX(-14deg);
  transition: background-color .2s ease-in-out;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #2e7840;
}

.Media-inscription-button:hover {
  color: white;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .Media .Media-info-article {
    padding: 20px;
  }

  .Media-button-wrapper {
    justify-content: center;
  }
}

.paquetes-title {
  font-family: "Raleway", sans-serif;
  font-weight: 700;

  margin-bottom: 30px;
  color: #348a49;
  text-align: center;
  font-size: 2rem;
  margin-top: 2rem;
}

.paquetes-subtitle {
  margin-bottom: 40px;
}

* {
  font-family: "Montserrat", sans-serif;
}

.paquete-type {
  background-color: #348a49;
  color: white;
  min-height: 300px;
}

.paquete-type h5 {
  font-weight: 700;
  text-transform: uppercase;
  font-style: italic;
  font-size: 35px;
}

.paquete-type h6 {
  font-size: 40px;
  font-weight: 700;
  margin-top: 40px;
  text-align: right;
}

.paquete-description {
  background-color: #1c4826;
  min-height: 300px;
  color: white;
  display: flex;
  justify-content: center;
}

.paquete-description p {
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
}

.yarl__fullsize {
  width: 100%;
  height: 100%;
}
.yarl__relative {
  position: relative;
}
.yarl__portal {
  position: fixed;
  inset: 0;
  opacity: 0;
  overflow: hidden;
  z-index: 9999;
  z-index: var(--yarl__portal_zindex, 9999);
  transition: opacity 250ms ease;
  transition: opacity var(--yarl__fade_animation_duration, 250ms) var(--yarl__fade_animation_timing_function, ease);
}
.yarl__portal_open {
  opacity: 1;
}
.yarl__container {
  position: absolute;
  inset: 0;
  overflow: hidden;
  background-color: #000;
  background-color: var(--yarl__container_background_color, var(--yarl__color_backdrop, #000));
  outline: 0;
  -webkit-user-select: none;
          user-select: none;
  touch-action: none;
  touch-action: var(--yarl__controller_touch_action, none);
  overscroll-behavior: contain;
  overscroll-behavior: var(--yarl__controller_overscroll-behavior, contain);
}
.yarl__carousel {
  display: flex;
  flex: 0 0 auto;
  height: 100%;
  align-content: center;
  justify-content: center;
  align-items: stretch;
  width: calc(100% + (var(--yarl__carousel_slides_count) - 1) * (100% + 0 * 1px + 0 * 1%));
  width: calc(100% + (var(--yarl__carousel_slides_count) - 1) * (100% + var(--yarl__carousel_spacing_px, 0) * 1px + var(--yarl__carousel_spacing_percent, 0) * 1%));
  -webkit-transform: translateX(0px);
  -webkit-transform: translateX(var(--yarl__swipe_offset, 0px));
          transform: translateX(0px);
          transform: translateX(var(--yarl__swipe_offset, 0px));
}
.yarl__carousel_with_slides {
  -webkit-column-gap: calc(0 * 1px + 100 / (100 * var(--yarl__carousel_slides_count) + (var(--yarl__carousel_slides_count) - 1) * 0) * 0 * 1%);
  -webkit-column-gap: calc(var(--yarl__carousel_spacing_px, 0) * 1px + 100 / (100 * var(--yarl__carousel_slides_count) + (var(--yarl__carousel_slides_count) - 1) * var(--yarl__carousel_spacing_percent, 0)) * var(--yarl__carousel_spacing_percent, 0) * 1%);
     -moz-column-gap: calc(0 * 1px + 100 / (100 * var(--yarl__carousel_slides_count) + (var(--yarl__carousel_slides_count) - 1) * 0) * 0 * 1%);
     -moz-column-gap: calc(var(--yarl__carousel_spacing_px, 0) * 1px + 100 / (100 * var(--yarl__carousel_slides_count) + (var(--yarl__carousel_slides_count) - 1) * var(--yarl__carousel_spacing_percent, 0)) * var(--yarl__carousel_spacing_percent, 0) * 1%);
          grid-column-gap: calc(0 * 1px + 100 / (100 * var(--yarl__carousel_slides_count) + (var(--yarl__carousel_slides_count) - 1) * 0) * 0 * 1%);
          column-gap: calc(0 * 1px + 100 / (100 * var(--yarl__carousel_slides_count) + (var(--yarl__carousel_slides_count) - 1) * 0) * 0 * 1%);
          grid-column-gap: calc(var(--yarl__carousel_spacing_px, 0) * 1px + 100 / (100 * var(--yarl__carousel_slides_count) + (var(--yarl__carousel_slides_count) - 1) * var(--yarl__carousel_spacing_percent, 0)) * var(--yarl__carousel_spacing_percent, 0) * 1%);
          column-gap: calc(var(--yarl__carousel_spacing_px, 0) * 1px + 100 / (100 * var(--yarl__carousel_slides_count) + (var(--yarl__carousel_slides_count) - 1) * var(--yarl__carousel_spacing_percent, 0)) * var(--yarl__carousel_spacing_percent, 0) * 1%);
}
.yarl__flex_center {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.yarl__slide {
  flex: 1 1;
  position: relative;
  padding: calc(0 * 1px + 100 / (100 * var(--yarl__carousel_slides_count) + (var(--yarl__carousel_slides_count) - 1) * 0) * 0 * 1%);
  padding: calc(var(--yarl__carousel_padding_px, 0) * 1px + 100 / (100 * var(--yarl__carousel_slides_count) + (var(--yarl__carousel_slides_count) - 1) * var(--yarl__carousel_spacing_percent, 0)) * var(--yarl__carousel_padding_percent, 0) * 1%);
}
[dir=rtl] .yarl__slide {
  --yarl__direction: -1;
}
.yarl__slide_image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  touch-action: none;
  touch-action: var(--yarl__controller_touch_action, none);
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}
@media screen and (min-width: 800px) {
  .yarl__slide_image {
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
  }
}
.yarl__slide_image_cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.yarl__slide_image_loading {
  opacity: 0;
}
.yarl__slide_placeholder {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  line-height: 0;
}
.yarl__slide_loading {
  color: rgba(255, 255, 255, 0.8);
  color: var(--yarl__slide_icon_loading_color, var(--yarl__color_button, rgba(255, 255, 255, 0.8)));
  -webkit-animation: yarl__delayed_fadein 1s linear;
          animation: yarl__delayed_fadein 1s linear;
}
.yarl__slide_loading line {
  -webkit-animation: yarl__stroke_opacity 1s linear infinite;
          animation: yarl__stroke_opacity 1s linear infinite;
}
.yarl__slide_loading line:nth-of-type(1) {
  -webkit-animation-delay: -1.875s;
          animation-delay: -1.875s;
}
.yarl__slide_loading line:nth-of-type(2) {
  -webkit-animation-delay: -1.75s;
          animation-delay: -1.75s;
}
.yarl__slide_loading line:nth-of-type(3) {
  -webkit-animation-delay: -1.625s;
          animation-delay: -1.625s;
}
.yarl__slide_loading line:nth-of-type(4) {
  -webkit-animation-delay: -1.5s;
          animation-delay: -1.5s;
}
.yarl__slide_loading line:nth-of-type(5) {
  -webkit-animation-delay: -1.375s;
          animation-delay: -1.375s;
}
.yarl__slide_loading line:nth-of-type(6) {
  -webkit-animation-delay: -1.25s;
          animation-delay: -1.25s;
}
.yarl__slide_loading line:nth-of-type(7) {
  -webkit-animation-delay: -1.125s;
          animation-delay: -1.125s;
}
.yarl__slide_loading line:nth-of-type(8) {
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}
.yarl__slide_error {
  width: 48px;
  width: var(--yarl__slide_icon_error_size, 48px);
  height: 48px;
  height: var(--yarl__slide_icon_error_size, 48px);
  color: red;
  color: var(--yarl__slide_icon_error_color, red);
}
@media (prefers-reduced-motion) {
  .yarl__portal, .yarl__slide {
    transition: unset;
  }
  .yarl__slide_loading, .yarl__slide_loading line {
    -webkit-animation: unset;
            animation: unset;
  }
}
.yarl__toolbar {
  position: absolute;
  inset: 0 0 auto auto;
  display: flex;
  justify-content: flex-end;
  padding: 8px;
  padding: var(--yarl__toolbar_padding, 8px);
}
[dir=rtl] .yarl__toolbar {
  inset: 0 auto auto 0;
}
.yarl__icon {
  width: 32px;
  width: var(--yarl__icon_size, 32px);
  height: 32px;
  height: var(--yarl__icon_size, 32px);
}
.yarl__button {
  cursor: pointer;
  -webkit-appearance: none;
          appearance: none;
  background-color: transparent;
  background-color: var(--yarl__button_background_color, transparent);
  border: 0;
  border: var(--yarl__button_border, 0);
  margin: 0;
  margin: var(--yarl__button_margin, 0);
  outline: none;
  line-height: 0;
  padding: 8px;
  padding: var(--yarl__button_padding, 8px);
  color: rgba(255, 255, 255, 0.8);
  color: var(--yarl__color_button, rgba(255, 255, 255, 0.8));
  -webkit-filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.8));
  -webkit-filter: var(--yarl__button_filter, drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.8)));
          filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.8));
          filter: var(--yarl__button_filter, drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.8)));
  -webkit-tap-highlight-color: transparent;
}
.yarl__button:focus {
  color: #fff;
  color: var(--yarl__color_button_active, #fff);
}
.yarl__button:focus:not(:focus-visible) {
  color: rgba(255, 255, 255, 0.8);
  color: var(--yarl__color_button, rgba(255, 255, 255, 0.8));
}
.yarl__button:focus-visible {
  color: #fff;
  color: var(--yarl__color_button_active, #fff);
}
@media (hover: hover) {
  .yarl__button:hover, .yarl__button:focus:hover, .yarl__button:focus-visible:hover {
    color: #fff;
    color: var(--yarl__color_button_active, #fff);
  }
}
.yarl__button:disabled {
  color: rgba(255, 255, 255, 0.4);
  color: var(--yarl__color_button_disabled, rgba(255, 255, 255, 0.4));
  cursor: default;
}
.yarl__navigation_prev, .yarl__navigation_next {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  padding: 24px 16px;
  padding: var(--yarl__navigation_button_padding, 24px 16px);
}
.yarl__navigation_prev {
  left: 0;
}
[dir=rtl] .yarl__navigation_prev {
  left: unset;
  right: 0;
  -webkit-transform: translateY(-50%) rotate(180deg);
          transform: translateY(-50%) rotate(180deg);
}
.yarl__navigation_next {
  right: 0;
}
[dir=rtl] .yarl__navigation_next {
  left: 0;
  right: unset;
  -webkit-transform: translateY(-50%) rotate(180deg);
          transform: translateY(-50%) rotate(180deg);
}
.yarl__no_scroll {
  height: 100%;
  overflow: hidden;
  overscroll-behavior: none;
}

@-webkit-keyframes yarl__delayed_fadein {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes yarl__delayed_fadein {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes yarl__stroke_opacity {
  from {
    stroke-opacity: 1;
  }
  to {
    stroke-opacity: 0.125;
  }
}
@keyframes yarl__stroke_opacity {
  from {
    stroke-opacity: 1;
  }
  to {
    stroke-opacity: 0.125;
  }
}

.Media .Media-banner {
  display: block;
  background-color: #348a49;
}

.Media .Media-banner p {
  margin: 0;
  margin-left: -15px;
  padding: 15px;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  display: inline-block;
}

.Media .Media-info-article {
  font-size: 1.2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 50px;
  font-weight: 700;
  width: 100%;
}

.Media .table {
  font-size: 1rem;
}

.Media .Media-title {
  padding: 15px;
  background-color: black;
  color: white;
  margin-bottom: 0;
  margin-right: -15px;
  margin-left: -15px;
  font-weight: 700;
  display: block;
  font-size: 2rem;
}

.Media .Media-info-title {
  margin-bottom: 40px;
  font-weight: 700;

}

.Media .Media-info-text {
  margin-bottom: 40px;
  font-size: 1rem;
  font-weight: 400;
}

.Media-inscription-button-wrapper {
  display: flex;
  justify-content: flex-end;
}

.Media-inscription-button {
  padding: 10px;
  color: white;
  position: relative;
  z-index: 1;
  display: inline-block;
  min-width: 160px;
  padding: 10px 20px;
  margin-left: 5px;
  margin-right: 5px;
  border: 1px solid transparent;
  background: 0 0;
  background-image: none;
  background-image: none;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  line-height: 20px;
  font-weight: 700;
  white-space: nowrap;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 11px;
  letter-spacing: .1em;
}

.Media-inscription-button::before {
  content: "";
  -webkit-transform: skewX(-14deg);
          transform: skewX(-14deg);
  transition: background-color .2s ease-in-out;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #348a49;
}

.Media-inscription-button:hover {
  color: white;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .Media .Media-info-article {
    padding: 20px;
  }

  .Media-button-wrapper {
    justify-content: center;
  }
}

.tab-header-list {
  margin-bottom: 1.5rem;
  border-bottom: 1px solid lightgray;
}

.tab-header-item {
  border: none;
  color: #444;
  padding: 1em;
  background-color: transparent;
  font-weight: 700;
  cursor: pointer;
}

.tab-header-item:hover {
  color: #348a49;
}

.tab-header-item:active,
.tab-header-item:focus {
  outline: none;
}

.tab-header-item[data-state="active"] {
  color: #348a49;
  border-bottom: 2px solid #348a49;
}

.Media .Media-banner {
  display: block;
  background-color: #348a49;
}

.Media .Media-banner p {
  margin: 0;
  margin-left: -15px;
  padding: 15px;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  display: inline-block;
}

.Media .Media-info-article {
  font-size: 1.2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 50px;
  font-weight: 700;
  width: 100%;
}

.Media .table {
  font-size: 1rem;
}

.Media .Media-title {
  padding: 15px;
  background-color: black;
  color: white;
  margin-bottom: 0;
  margin-right: -15px;
  margin-left: -15px;
  font-weight: 700;
  display: block;
  font-size: 2rem;
}

.Media .Media-info-title {
  margin-bottom: 40px;
  font-weight: 700;

}

.Media .Media-info-text {
  margin-bottom: 40px;
  font-size: 1rem;
  font-weight: 400;
}

.Media-inscription-button-wrapper {
  display: flex;
  justify-content: flex-end;
}

.Media-inscription-button {
  padding: 10px;
  color: white;
  position: relative;
  z-index: 1;
  display: inline-block;
  min-width: 160px;
  padding: 10px 20px;
  margin-left: 5px;
  margin-right: 5px;
  border: 1px solid transparent;
  background: 0 0;
  background-image: none;
  background-image: none;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  line-height: 20px;
  font-weight: 700;
  white-space: nowrap;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 11px;
  letter-spacing: .1em;
}

.Media-inscription-button::before {
  content: "";
  -webkit-transform: skewX(-14deg);
          transform: skewX(-14deg);
  transition: background-color .2s ease-in-out;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #348a49;
}

.Media-inscription-button:hover {
  color: white;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .Media .Media-info-article {
    padding: 20px;
  }

  .Media-button-wrapper {
    justify-content: center;
  }
}

.tab-header-list {
  margin-bottom: 1.5rem;
  border-bottom: 1px solid lightgray;
}

.tab-header-item {
  border: none;
  color: #444;
  padding: 1em;
  background-color: transparent;
  font-weight: 700;
  cursor: pointer;
}

.tab-header-item:hover {
  color: #348a49;
}

.tab-header-item:active,
.tab-header-item:focus {
  outline: none;
}

.tab-header-item[data-state="active"] {
  color: #348a49;
  border-bottom: 2px solid #348a49;
}

.tab-content-wrapper {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}

@media screen and (min-width: 768px) {
  .tab-content-wrapper {
    flex-direction: row;
  }
}
