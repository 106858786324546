.gps {
  font-family: "Sedgwick Ave Display", cursive;
  font-size: 45px !important;
}

.slider-caption {
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  margin: 0;
  z-index: 4;
  max-width: 80%;
  text-align: center;
}

.slider-caption h1 {
  font-weight: bold;
  font-size: 65px;
}


.slider-caption h3 {
  text-align: center;
}

.slider-caption h5 {
  font-weight: bold;
  font-size: 32px;
}

.slider-caption p {
  font-weight: bold;
  font-size: 25px;
}

.slider {
  position: relative;
  width: 100%;
  margin: 0 auto;
  height: 600px;
  overflow: hidden;
  white-space: nowrap;
}

.slider-wrapper {
  position: relative;
  height: 600px;
  width: 100%;
  transition: transform ease-out 0.5s;
}

.slider-black {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  height: 600px;
  width: 100%;
  background-color: black;
  opacity: 0.3;
}

.slider-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  height: 600px;
  width: 100%;
  background-repeat: no-repeat;
}

.slide {
  display: inline-block;
  height: 600px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 60%;
}

.right-arrow,
.left-arrow {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f9f9f9;
  border-radius: 50%;
  cursor: pointer;
  transition: transform ease-in 0.1s;
}

.right-arrow:hover,
.left-arrow:hover {
  transition: transform ease-in 0.1s;
  transform: scale(1.1);
}

.cool-buttons {
  background: black;
  border: 2px solid white;
}

.right-arrow {
  position: absolute;
  top: 50%;
  right: 25px;
  z-index: 3;
  color: #fff;
}

.right-arrow img {
  transform: translate(2px, 0);
}

.right-arrow img:focus {
  outline: 0;
}

.left-arrow {
  position: absolute;
  top: 50%;
  left: 25px;
  z-index: 3;
  color: #fff;
}

.left-arrow img {
  transform: translate(-2px, 0);
}

.left-arrow img:focus {
  outline: 0;
}

@media screen and (max-width: 768px) {
  .slider-wrapper,
  .slider-overlay,
  .slider,
  .slide {
    height: 250px !important;
  }

  .slider-caption {
    bottom: 0;
  }

  .slider-caption {
    transform: translateX(-50%);
  }

  .slider-caption h1 {
    font-size: 6vmin !important;
  }

  .slider-caption h5 {
    font-size: 4vmin !important;
  }

  .slider-caption p {
    font-size: 3vmin !important;
  }

  .right-arrow {
    top: 76%;
  }

  .left-arrow {
    top: 76%;
  }
}
