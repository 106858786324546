.Media .Media-banner {
  display: block;
  background-color: #348a49;
}

.Media .Media-banner p {
  margin: 0;
  margin-left: -15px;
  padding: 15px;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  display: inline-block;
}

.Media .Media-info-article {
  font-size: 1.2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 50px;
  font-weight: 700;
  width: 100%;
}

.Media .table {
  font-size: 1rem;
}

.Media .Media-title {
  padding: 15px;
  background-color: black;
  color: white;
  margin-bottom: 0;
  margin-right: -15px;
  margin-left: -15px;
  font-weight: 700;
  display: block;
  font-size: 2rem;
}

.Media .Media-info-title {
  margin-bottom: 40px;
  font-weight: 700;

}

.Media .Media-info-text {
  margin-bottom: 40px;
  font-size: 1rem;
  font-weight: 400;
}

.Media-inscription-button-wrapper {
  display: flex;
  justify-content: flex-end;
}

.Media-inscription-button {
  padding: 10px;
  color: white;
  position: relative;
  z-index: 1;
  display: inline-block;
  min-width: 160px;
  padding: 10px 20px;
  margin-left: 5px;
  margin-right: 5px;
  border: 1px solid transparent;
  background: 0 0;
  background-image: none;
  background-image: none;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  line-height: 20px;
  font-weight: 700;
  white-space: nowrap;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 11px;
  letter-spacing: .1em;
}

.Media-inscription-button::before {
  content: "";
  transform: skewX(-14deg);
  transition: background-color .2s ease-in-out;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #348a49;
}

.Media-inscription-button:hover {
  color: white;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .Media .Media-info-article {
    padding: 20px;
  }

  .Media-button-wrapper {
    justify-content: center;
  }
}

.tab-header-list {
  margin-bottom: 1.5rem;
  border-bottom: 1px solid lightgray;
}

.tab-header-item {
  border: none;
  color: #444;
  padding: 1em;
  background-color: transparent;
  font-weight: 700;
  cursor: pointer;
}

.tab-header-item:hover {
  color: #348a49;
}

.tab-header-item:active,
.tab-header-item:focus {
  outline: none;
}

.tab-header-item[data-state="active"] {
  color: #348a49;
  border-bottom: 2px solid #348a49;
}

.tab-content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media screen and (min-width: 768px) {
  .tab-content-wrapper {
    flex-direction: row;
  }
}