.Rates .Rates-banner {
  display: block;
  background-color: #2e7840;
}

.Rates .Rates-banner p {
  margin: 0;
  margin-left: -15px;
  padding: 15px;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  display: inline-block;
}

.Rates .Rates-info-article {
  font-size: 1.2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 50px;
  font-weight: 700;
  width: 100%;
}

.Rates .table {
  font-size: 1rem;
}

.Rates .Rates-title {
  padding: 15px;
  background-color: black;
  color: white;
  margin-bottom: 0;
  margin-right: -15px;
  margin-left: -15px;
  font-weight: 700;
  display: block;
  font-size: 2rem;
}

.Rates .Rates-info-title {
  margin-bottom: 40px;
  font-weight: 700;

}

.Rates .Rates-info-text {
  margin-bottom: 40px;
  font-size: 1rem;
  font-weight: 400;
}

.Rates-inscription-button-wrapper {
  display: flex;
  justify-content: flex-end;
}

.Rates-inscription-button {
  padding: 10px;
  color: white;
  position: relative;
  z-index: 1;
  display: inline-block;
  min-width: 160px;
  padding: 10px 20px;
  margin-left: 5px;
  margin-right: 5px;
  border: 1px solid transparent;
  background: 0 0;
  background-image: none;
  background-image: none;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  line-height: 20px;
  font-weight: 700;
  white-space: nowrap;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 11px;
  letter-spacing: .1em;
}

.Rates-inscription-button::before {
  content: "";
  transform: skewX(-14deg);
  transition: background-color .2s ease-in-out;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #2e7840;
}

.Rates-inscription-button:hover {
  color: white;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .Rates .Rates-info-article {
    padding: 20px;
  }

  .Rates-inscription-button-wrapper {
    justify-content: center;
  }
}

.tarifas-title {
  font-family: "Raleway", sans-serif;
  font-weight: 700;

  margin-bottom: 30px;
  color: #348a49;
  text-align: center;
  font-size: 2rem;
  margin-top: 2rem;
}

.que-incluye {
  background-color: #348a49;
  color: white;
  padding: 90px;
  height: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  text-align: justify;
}

.que-incluye h6 {
  text-transform: uppercase;
  font-size: 1.3rem;
  font-weight: 700;
  font-family: "Raleway", sans-serif;
}

.lista-tarifas {
  padding: 40px 100px;
  height: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
}

@media screen and (max-width: 768px) {
  .lista-tarifas,
  .que-incluye {
    padding: 40px;
  }
}

.paquetes-banner {
  position: relative;
  height: 450px;
  overflow: hidden;
}

.paquetes-banner .overlay {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.6;
}

.paquetes-banner .paquetes-banner-info {
  position: absolute;
  top: 50%;
  left: 65%;
  transform: translate(-50%, -50%);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
}

.paquetes-banner .paquetes-banner-info h5 {
  font-size: 2rem;
  text-transform: uppercase;
  font-family: "Raleway", sans-serif;
  text-align: center;
}

.paquetes-banner .paquetes-banner-info p,
.paquetes-banner .paquetes-banner-info a {
  text-align: center;
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 15px;
}

span {
  font-size: 17px;
  text-decoration: underline;
}

.periodo-title {
  background-color: #348a49;
  display: inline-block;
  padding: 0.5rem;
  color: white;
  border-radius: 0.5rem;
}

* {
  text-align: justify;
}
