.Header {
  position: absolute;
  align-items: center;
  display: flex;
  top: 0;
  bottom: 0;
  height: 150px;
  z-index: 99;
  width: 100%;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 16%);
}

.header-homepage .Header {
  background-color: rgba(0,0,0,0.4);
  box-shadow: none;
}

.Header .Header-top {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #2e7840;
  padding: 0.5rem;
  display: flex;
  justify-content: flex-end;
}

.Header .Header-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  padding: 0 0.5rem;
}

@media screen and (max-width: 768px) {
  .Header .Header-container {
    flex-direction: column;
    justify-content: center;
    padding: 0;
  }
}

.Header .Logo-wrapper {
  width: 130px;
}

.Header .Logo-wrapper .Logo-img {
  width: 100%;
}

.Header .Header-navbar {
  display: flex;
}

.Header .Header-navbar .Header-list {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  /* height: 90px; */
}

@media screen and (max-width: 768px) {
  .Header .Header-navbar .Header-list {
    width: 100%;
    justify-content: space-around;
  }
}

.Header .Header-navbar .Header-list .Header-item {
  display: flex;
  padding: 0 0.7rem;
  align-items: center;
}

.Header .Header-navbar .Header-list .Header-item .dropdown-menu {
  top: -25px !important;
  background-color: #2e7840;
  border-radius: 0;
}

.Header .Header-navbar .Header-list .Header-item .dropdown-menu .dropdown-item-a {
  color: white !important;
  font-weight: 600;
}

.Header .Header-navbar .Header-list .Header-item .dropdown-menu .dropdown-item-a:hover {
  background: none;
  opacity: 0.8;
}

@media screen and (max-width: 768px) {
  .Header .Header-navbar .Header-list .Header-item {
    padding: 0 0.35rem;
  }
}

.Header .Header-navbar .Header-list .Header-item .Header-link {
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 0.85rem;
  font-weight: 800;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  color: #444;
}

.header-homepage .Header .Header-navbar .Header-list .Header-item .Header-link {
  color: white;
}

.Header .Header-navbar .Header-list .Header-item .Header-link.Header-link-inscription {
  color: white;
}

.Header .Header-navbar .Header-list .Header-item .Header-link.Header-link-inscription span {
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 0.85rem;
  font-weight: 800;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
}

.Header-link-dropdown {
  background-color: transparent !important;
  padding: 0 !important;
}

.Header-link-dropdown:focus {
  outline: none !important;
}

@media screen and (max-width: 768px) {
  .Header .Header-navbar .Header-list .Header-item .Header-link {
    font-size: 0.85rem;
  }
}

.Header-item-inscription {
  color: white;
  background-color: #2e7840;
}

.Header-item-check-inscription {
  color: white;
  background-color: black;
}

.Header-item ul {
  background-color: #2e7840;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.Header-item ul > li.dropdown-item:hover {
  background-color: #1d502a;
  color: white;
}
