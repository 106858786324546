.Seguro .Seguro-banner {
  display: block;
  background-color: #2e7840;
}

.Seguro .Seguro-banner p {
  margin: 0;
  margin-left: -15px;
  padding: 15px;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  display: inline-block;
}

.Seguro .Seguro-info-article {
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 50px;
  font-weight: 700;
  width: 100%;
}

.Seguro .table {
  font-size: 1rem;
}

.Seguro .Seguro-title {
  padding: 15px;
  background-color: black;
  color: white;
  margin-bottom: 0;
  margin-right: -15px;
  margin-left: -15px;
  font-weight: 700;
  display: block;
  font-size: 2rem;
}

.Seguro .Seguro-info-title {
  margin-bottom: 40px;
  font-weight: 700;

}

.Seguro .Seguro-info-text {
  margin-bottom: 40px;
  font-size: 1rem;
  font-weight: 400;
}

.Seguro-pdf-button-wrapper {
  display: flex;
  justify-content: flex-end;
}

.Seguro-pdf-button {
  padding: 10px;
  color: white;
  position: relative;
  z-index: 1;
  display: inline-block;
  min-width: 160px;
  padding: 10px 20px;
  margin-left: 5px;
  margin-right: 5px;
  border: 1px solid transparent;
  background: 0 0;
  background-image: none;
  background-image: none;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  line-height: 20px;
  font-weight: 700;
  white-space: nowrap;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 11px;
  letter-spacing: .1em;
}

.Seguro-pdf-button::before {
  content: "";
  transform: skewX(-14deg);
  transition: background-color .2s ease-in-out;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #2e7840;
}

.Seguro-pdf-button:hover {
  color: white;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .Seguro .Seguro-info-article {
    padding: 20px;
  }

  .Seguro-button-wrapper {
    display: flex;
    justify-content: center;
  }
}

.tarifas-title {
  font-family: "Raleway", sans-serif;
  font-weight: 700;

  margin-bottom: 30px;
  color: #348a49;
  text-align: center;
  font-size: 2rem;
  margin-top: 2rem;
}